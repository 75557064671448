import React from 'react'
import * as Sentry from '@sentry/gatsby'
import smoothScroll from 'smooth-scroll'
import { config } from '@fortawesome/fontawesome-svg-core'
import { AuthProvider } from './src/hooks/useAuth'
import { isMobile } from './src/utils'
import { init } from './src/analytics'

import './src/all.scss'

config.autoAddCss = false // SSR + https://fontawesome.com/how-to-use/on-the-web/other-topics/security

let _smoothScroll = null

export const onClientEntry = async () => {
  init()

  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
  if (window.fetch) {
    await import(`unfetch/polyfill`)
  }
}

export const onRouteUpdate = () => {
  if (_smoothScroll) _smoothScroll.destroy()
  _smoothScroll = smoothScroll('a[href*="#"]', {
    durationMax: 1000,
    updateURL: false,
    header: isMobile ? undefined : '#header'
  })
}

export const wrapRootElement = ({ element }) => (
  <Sentry.ErrorBoundary>
    <AuthProvider>{element}</AuthProvider>
  </Sentry.ErrorBoundary>
)

export const shouldUpdateScroll = () => {
  return true
}
