export const MISSIONS = {
  mission1: {
    index: 1,
    title: 'לייב שיואוווו',
    ends: 'מסתיימת ב-29/06',
    personalLink: 'https://www.karinnarts.co.il/academy/live-show-figure/',
    description: `
      לייב שואו פתוח לכולם (וללא עלות) לכבוד חגיגות השנה של האקדמיה לעיצוב עוגות 🎉

      המשימה שלך היא לשווק את הלייב שואו בכל ערוצי השיווק שאת משתמשת ומרגישה בהן בנוח ביום יום בעזרת לינק אישי שייחודי רק לך.

      שיווק בכל ערוץ מזכה ב-50 נקודות.
      על כל נרשמת ללייב שואו דרך הלינק האישי שלך את מקבלת עוד 5 נקודות.

      שימי לב שמספר פרסומים באותו הערוץ לא מקנה נקודות נוספות אבל אם זה גורם ליותר נרשמות להירשם אז את כן מקבלת 5 נקודות נוספות על כל נרשמת.

      דגש חשוב - יש סוג דיווח ״אחר״ - מדובר על פרסום בערוץ שיווק חדש שלא נמצא ברשימה, לא להשתמש בזה לדיווח נוסף על ערוץ שכן מופיע ברשימה.
    `,
    points: 50,
    tasks: {
      facebook1: {
        title: 'פייסבוק - פוסט בפרופיל האישי',
        type: 'link'
      },
      facebook2: {
        title: 'פייסבוק - סטורי בפרופיל האישי',
        type: 'image'
      },
      facebook3: {
        title: 'פייסבוק - ריל בפרופיל האישי',
        type: 'link'
      },
      facebook4: {
        title: 'פייסבוק - פוסט בדף העסקי',
        type: 'link'
      },
      facebook5: {
        title: 'פייסבוק - סטורי בדף העסקי',
        type: 'image'
      },
      facebook6: {
        title: 'פייסבוק - ריל בדף העסקי',
        type: 'link'
      },
      facebook7: {
        title: 'פייסבוק - קבוצת ״כיתת אומן | עיצוב עוגות בבצק סוכר״',
        type: 'link'
      },
      facebook8: {
        title: 'פייסבוק - קבוצת ״מיקסר״',
        type: 'link'
      },
      facebook9: {
        title: 'פייסבוק - קבוצה נוספת 1',
        type: 'linkOrImage'
      },
      facebook10: {
        title: 'פייסבוק - קבוצה נוספת 2',
        type: 'linkOrImage'
      },
      facebook11: {
        title: 'פייסבוק - קבוצה נוספת 3',
        type: 'linkOrImage'
      },
      instagram1: {
        title: 'אינסטגרם - פוסט',
        type: 'link'
      },
      instagram2: {
        title: 'אינסטגרם - סטורי',
        type: 'image'
      },
      instagram3: {
        title: 'אינסטגרם - ריל',
        type: 'link'
      },
      whatsapp1: {
        title: 'וואטסאפ - סטטוס',
        type: 'image'
      },
      whatsapp2: {
        title: 'וואטסאפ - קבוצה 1',
        type: 'image'
      },
      whatsapp3: {
        title: 'וואטסאפ - קבוצה 2',
        type: 'image'
      },
      whatsapp4: {
        title: 'וואטסאפ - קבוצה 3',
        type: 'image'
      },
      whatsapp5: {
        title: 'וואטסאפ - קבוצה 4',
        type: 'image'
      },
      whatsapp6: {
        title: 'וואטסאפ - קבוצה 5',
        type: 'image'
      },
      youtube: {
        title: 'יוטיוב - סרטון',
        type: 'link'
      },
      tiktok: {
        title: 'טיקטוק - סרטון',
        type: 'link'
      },
      friend: {
        title: 'הודעה אישית לחברה',
        type: 'image'
      },
      other: {
        title: 'אחר',
        type: 'linkOrImage'
      },
      ref: {
        title: 'הזמנת חברות',
        ignore: true,
        points: 5
      },
      guess: {
        title: 'ניחוש נכון של הדמות',
        type: 'link',
        ignore: true,
        points: 200
      }
    }
  },
  mission2: {
    index: 2,
    title: 'ניצול הזדמנויות',
    ends: 'מסתיימת ב-02/07',
    personalLink: 'https://www.karinnarts.co.il/academy/live-show/',
    description: `
      מכירה מוקדמת של Live Show מטורף לכבוד יום הולדת 3 של אריאלי שלי 🎉

      המשימה היא לשווק את המכירה המוקדמת של ה Live Show בערוץ שיווק שעובד לך הכי טוב בעזרת לינק אישי שייחודי רק לך.
      הערוץ שדרכו הצלחת להביא בנות שיירשמו ללייב שואו החינמי, זה הערוץ שנכון לפרסם בו את הלייב שואו בתשלום (הלייב שואו החינמי חימם את הקהל שלך וחלקו מוכן בשלב הבא וכל הרעיון הוא לתת עוד דחיפה קלה).

      בונוס מיוחד יינתן למי שתצליח להביא נרשמות ל Live Show (ראי משימה #3) ואם את חושבת שפרסום בערוצים נוספים כבר עכשיו יעזור לזה אז לכי על זה.

      ביצוע המשימה יזכה אותך ב-50 נקודות.
    `,
    points: 50,
    tasks: {
      other: {
        title: 'הדיווח שלי',
        type: 'linkOrImage'
      }
    }
  },
  mission3: {
    index: 3,
    title: 'Live Show',
    ends: 'מסתיימת ב-31/07',
    personalLink: 'https://www.karinnarts.co.il/academy/live-show/',
    description: `
      Live Show מטורף לכבוד יום הולדת 3 של אריאלי שלי 🎉

      המשימה היא לצרף בנות שאינן חברות באקדמיה ל Live Show בכל דרך שאת רואה לנכון.

      על כל מצטרפת ל - Live Show את מקבלת 1000 (!) נקודות.

      אין צורך לדווח על ביצוע המשימה כי אנחנו נדע לפי הצטרפות דרך הלינק האישי שלך.
    `,
    points: 1000,
    tasks: {}
  },
  mission4: {
    index: 4.1,
    title: 'סיבה למסיבה #1',
    ends: 'מסתיימת ב-09/08',
    description: `
      לכל מי שמתבאסת שיש לה קורסים שהיא עדיין לא התחילה, בין אם זה זמן, מוטיבציה או שסתם אין לה למי להכין הנה משימה בשבילך!

      המשימה היא לבחור קורס שעדיין לא התחלת ופשוט לעשות אותו 🤩

      לכל קורס יש סרגל התקדמות (ברשימת ״הקורסים שלי״ מתחת לשם של כל קורס וגם בתוך דף הקורס עצמו) וכל התקדמות של 10% מזכה ב-100 נקודות.

      בכל דיווח (בכל התקדמות של 10% בעצם) יש להעלות תמונה אחת שבה רואים גם את היצירה בשלב הנוכחי וגם את סרגל ההתקדמות (אפשר לצלם את היצירה על רקע מסך המחשב, לקרב את הנייד ליד היצירה כשברקע שלו רואים את הסרגל או כל דרך יצירתית אחרת).

      בסוף כשתוצר הקורס מוכן, תעשי פוסט/סטורי/סטטוס (מה שעובד לך הכי טוב) עם תמונת התוצר הסופי ותעודת הסיום, צייני שזה נעשה במסגרת קורס באקדמיה לעיצוב עוגות וספרי מה לקחת מהקורס הזה.
      פרסום התוצר ברשתות מזכה אותה ב-200 נקודות.

      שימי לב שניתן לבצע את המשימה עד סוף המשחק אז תתכנני את הזמן בהתאם.

      המשימה מיועדת לקורס שעדיין לא התחלת (או שבדיוק התחלת).
    `,
    points: 100,
    tasks: {
      progress10: {
        title: 'התקדמות - 10%',
        type: 'image'
      },
      progress20: {
        title: 'התקדמות - 20%',
        type: 'image'
      },
      progress30: {
        title: 'התקדמות - 30%',
        type: 'image'
      },
      progress40: {
        title: 'התקדמות - 40%',
        type: 'image'
      },
      progress50: {
        title: 'התקדמות - 50%',
        type: 'image'
      },
      progress60: {
        title: 'התקדמות - 60%',
        type: 'image'
      },
      progress70: {
        title: 'התקדמות - 70%',
        type: 'image'
      },
      progress80: {
        title: 'התקדמות - 80%',
        type: 'image'
      },
      progress90: {
        title: 'התקדמות - 90%',
        type: 'image'
      },
      progress100: {
        title: 'סיום - 100%',
        type: 'image'
      },
      marketing: {
        title: 'פרסום היצירה באחד מערוצי השיווק ברשתות החברתיות',
        type: 'linkOrImage',
        points: 200
      }
    }
  },
  'mission4.2': {
    index: 4.2,
    title: 'סיבה למסיבה #2',
    ends: 'מסתיימת ב-09/08',
    description: `
      לכל מי שמתבאסת שיש לה קורסים שהיא עדיין לא התחילה, בין אם זה זמן, מוטיבציה או שסתם אין לה למי להכין הנה משימה בשבילך!

      המשימה היא לבחור קורס שעדיין לא התחלת ופשוט לעשות אותו 🤩

      לכל קורס יש סרגל התקדמות (ברשימת ״הקורסים שלי״ מתחת לשם של כל קורס וגם בתוך דף הקורס עצמו) וכל התקדמות של 10% מזכה ב-100 נקודות.

      בכל דיווח (בכל התקדמות של 10% בעצם) יש להעלות תמונה אחת שבה רואים גם את היצירה בשלב הנוכחי וגם את סרגל ההתקדמות (אפשר לצלם את היצירה על רקע מסך המחשב, לקרב את הנייד ליד היצירה כשברקע שלו רואים את הסרגל או כל דרך יצירתית אחרת).

      בסוף כשתוצר הקורס מוכן, תעשי פוסט/סטורי/סטטוס (מה שעובד לך הכי טוב) עם תמונת התוצר הסופי ותעודת הסיום, צייני שזה נעשה במסגרת קורס באקדמיה לעיצוב עוגות וספרי מה לקחת מהקורס הזה.
      פרסום התוצר ברשתות מזכה אותה ב-200 נקודות.

      שימי לב שניתן לבצע את המשימה עד סוף המשחק אז תתכנני את הזמן בהתאם.

      המשימה מיועדת לקורס שעדיין לא התחלת (או שבדיוק התחלת).
    `,
    points: 100,
    tasks: {
      progress10: {
        title: 'התקדמות - 10%',
        type: 'image'
      },
      progress20: {
        title: 'התקדמות - 20%',
        type: 'image'
      },
      progress30: {
        title: 'התקדמות - 30%',
        type: 'image'
      },
      progress40: {
        title: 'התקדמות - 40%',
        type: 'image'
      },
      progress50: {
        title: 'התקדמות - 50%',
        type: 'image'
      },
      progress60: {
        title: 'התקדמות - 60%',
        type: 'image'
      },
      progress70: {
        title: 'התקדמות - 70%',
        type: 'image'
      },
      progress80: {
        title: 'התקדמות - 80%',
        type: 'image'
      },
      progress90: {
        title: 'התקדמות - 90%',
        type: 'image'
      },
      progress100: {
        title: 'סיום - 100%',
        type: 'image'
      },
      marketing: {
        title: 'פרסום היצירה באחד מערוצי השיווק ברשתות החברתיות',
        type: 'linkOrImage',
        points: 200
      }
    }
  },
  'mission4.3': {
    index: 4.3,
    title: 'סיבה למסיבה #3',
    ends: 'מסתיימת ב-09/08',
    description: `
      לכל מי שמתבאסת שיש לה קורסים שהיא עדיין לא התחילה, בין אם זה זמן, מוטיבציה או שסתם אין לה למי להכין הנה משימה בשבילך!

      המשימה היא לבחור קורס שעדיין לא התחלת ופשוט לעשות אותו 🤩

      לכל קורס יש סרגל התקדמות (ברשימת ״הקורסים שלי״ מתחת לשם של כל קורס וגם בתוך דף הקורס עצמו) וכל התקדמות של 10% מזכה ב-100 נקודות.

      בכל דיווח (בכל התקדמות של 10% בעצם) יש להעלות תמונה אחת שבה רואים גם את היצירה בשלב הנוכחי וגם את סרגל ההתקדמות (אפשר לצלם את היצירה על רקע מסך המחשב, לקרב את הנייד ליד היצירה כשברקע שלו רואים את הסרגל או כל דרך יצירתית אחרת).

      בסוף כשתוצר הקורס מוכן, תעשי פוסט/סטורי/סטטוס (מה שעובד לך הכי טוב) עם תמונת התוצר הסופי ותעודת הסיום, צייני שזה נעשה במסגרת קורס באקדמיה לעיצוב עוגות וספרי מה לקחת מהקורס הזה.
      פרסום התוצר ברשתות מזכה אותה ב-200 נקודות.

      שימי לב שניתן לבצע את המשימה עד סוף המשחק אז תתכנני את הזמן בהתאם.

      המשימה מיועדת לקורס שעדיין לא התחלת (או שבדיוק התחלת).
    `,
    points: 100,
    tasks: {
      progress10: {
        title: 'התקדמות - 10%',
        type: 'image'
      },
      progress20: {
        title: 'התקדמות - 20%',
        type: 'image'
      },
      progress30: {
        title: 'התקדמות - 30%',
        type: 'image'
      },
      progress40: {
        title: 'התקדמות - 40%',
        type: 'image'
      },
      progress50: {
        title: 'התקדמות - 50%',
        type: 'image'
      },
      progress60: {
        title: 'התקדמות - 60%',
        type: 'image'
      },
      progress70: {
        title: 'התקדמות - 70%',
        type: 'image'
      },
      progress80: {
        title: 'התקדמות - 80%',
        type: 'image'
      },
      progress90: {
        title: 'התקדמות - 90%',
        type: 'image'
      },
      progress100: {
        title: 'סיום - 100%',
        type: 'image'
      },
      marketing: {
        title: 'פרסום היצירה באחד מערוצי השיווק ברשתות החברתיות',
        type: 'linkOrImage',
        points: 200
      }
    }
  },
  'mission4.4': {
    index: 4.4,
    title: 'סיבה למסיבה #4',
    ends: 'מסתיימת ב-09/08',
    description: `
      לכל מי שמתבאסת שיש לה קורסים שהיא עדיין לא התחילה, בין אם זה זמן, מוטיבציה או שסתם אין לה למי להכין הנה משימה בשבילך!

      המשימה היא לבחור קורס שעדיין לא התחלת ופשוט לעשות אותו 🤩

      לכל קורס יש סרגל התקדמות (ברשימת ״הקורסים שלי״ מתחת לשם של כל קורס וגם בתוך דף הקורס עצמו) וכל התקדמות של 10% מזכה ב-100 נקודות.

      בכל דיווח (בכל התקדמות של 10% בעצם) יש להעלות תמונה אחת שבה רואים גם את היצירה בשלב הנוכחי וגם את סרגל ההתקדמות (אפשר לצלם את היצירה על רקע מסך המחשב, לקרב את הנייד ליד היצירה כשברקע שלו רואים את הסרגל או כל דרך יצירתית אחרת).

      בסוף כשתוצר הקורס מוכן, תעשי פוסט/סטורי/סטטוס (מה שעובד לך הכי טוב) עם תמונת התוצר הסופי ותעודת הסיום, צייני שזה נעשה במסגרת קורס באקדמיה לעיצוב עוגות וספרי מה לקחת מהקורס הזה.
      פרסום התוצר ברשתות מזכה אותה ב-200 נקודות.

      שימי לב שניתן לבצע את המשימה עד סוף המשחק אז תתכנני את הזמן בהתאם.

      המשימה מיועדת לקורס שעדיין לא התחלת (או שבדיוק התחלת).
    `,
    points: 100,
    tasks: {
      progress10: {
        title: 'התקדמות - 10%',
        type: 'image'
      },
      progress20: {
        title: 'התקדמות - 20%',
        type: 'image'
      },
      progress30: {
        title: 'התקדמות - 30%',
        type: 'image'
      },
      progress40: {
        title: 'התקדמות - 40%',
        type: 'image'
      },
      progress50: {
        title: 'התקדמות - 50%',
        type: 'image'
      },
      progress60: {
        title: 'התקדמות - 60%',
        type: 'image'
      },
      progress70: {
        title: 'התקדמות - 70%',
        type: 'image'
      },
      progress80: {
        title: 'התקדמות - 80%',
        type: 'image'
      },
      progress90: {
        title: 'התקדמות - 90%',
        type: 'image'
      },
      progress100: {
        title: 'סיום - 100%',
        type: 'image'
      },
      marketing: {
        title: 'פרסום היצירה באחד מערוצי השיווק ברשתות החברתיות',
        type: 'linkOrImage',
        points: 200
      }
    }
  },
  'mission4.5': {
    index: 4.5,
    title: 'סיבה למסיבה #5',
    ends: 'מסתיימת ב-09/08',
    description: `
      לכל מי שמתבאסת שיש לה קורסים שהיא עדיין לא התחילה, בין אם זה זמן, מוטיבציה או שסתם אין לה למי להכין הנה משימה בשבילך!

      המשימה היא לבחור קורס שעדיין לא התחלת ופשוט לעשות אותו 🤩

      לכל קורס יש סרגל התקדמות (ברשימת ״הקורסים שלי״ מתחת לשם של כל קורס וגם בתוך דף הקורס עצמו) וכל התקדמות של 10% מזכה ב-100 נקודות.

      בכל דיווח (בכל התקדמות של 10% בעצם) יש להעלות תמונה אחת שבה רואים גם את היצירה בשלב הנוכחי וגם את סרגל ההתקדמות (אפשר לצלם את היצירה על רקע מסך המחשב, לקרב את הנייד ליד היצירה כשברקע שלו רואים את הסרגל או כל דרך יצירתית אחרת).

      בסוף כשתוצר הקורס מוכן, תעשי פוסט/סטורי/סטטוס (מה שעובד לך הכי טוב) עם תמונת התוצר הסופי ותעודת הסיום, צייני שזה נעשה במסגרת קורס באקדמיה לעיצוב עוגות וספרי מה לקחת מהקורס הזה.
      פרסום התוצר ברשתות מזכה אותה ב-200 נקודות.

      שימי לב שניתן לבצע את המשימה עד סוף המשחק אז תתכנני את הזמן בהתאם.

      המשימה מיועדת לקורס שעדיין לא התחלת (או שבדיוק התחלת).
    `,
    points: 100,
    tasks: {
      progress10: {
        title: 'התקדמות - 10%',
        type: 'image'
      },
      progress20: {
        title: 'התקדמות - 20%',
        type: 'image'
      },
      progress30: {
        title: 'התקדמות - 30%',
        type: 'image'
      },
      progress40: {
        title: 'התקדמות - 40%',
        type: 'image'
      },
      progress50: {
        title: 'התקדמות - 50%',
        type: 'image'
      },
      progress60: {
        title: 'התקדמות - 60%',
        type: 'image'
      },
      progress70: {
        title: 'התקדמות - 70%',
        type: 'image'
      },
      progress80: {
        title: 'התקדמות - 80%',
        type: 'image'
      },
      progress90: {
        title: 'התקדמות - 90%',
        type: 'image'
      },
      progress100: {
        title: 'סיום - 100%',
        type: 'image'
      },
      marketing: {
        title: 'פרסום היצירה באחד מערוצי השיווק ברשתות החברתיות',
        type: 'linkOrImage',
        points: 200
      }
    }
  },
  mission5: {
    index: 5,
    title: 'נעים להכיר',
    ends: 'מסתיימת ב-05/07',
    description: `
      משימת היכרות קלילה וכייפית בתוך קבוצת הוואטסאפ של האקדמיה.
      אגב, זו גם הזדמנות להיכנס למשחק אם עדיין לא נכנסת וזו גם הזדמנות בשבילכן לעודד עוד חברות להצטרף 💪

      המשימה היא לכתוב הודעה קצרה בקבוצת האקדמיה לפי התבנית הבאה:

      1. שם מלא
      2. מאיפה את בארץ
      3. אם היית יכולה לבחור מישהו אחד בכל העולם, למי היית מכינה עוגה מעוצבת?
      4. תמונה של עוגה/דמות שלך שאת ממש עפה עליה (רק תמונה, לא לכתוב עליה כלום)

      שימי לב שניתן לבצע את המשימה עד יום רביעי (05/07) בחצות.
      המשימה הזו שווה 120 נקודות!

      יש לצלם צילום מסך של ההודעה שלך בתוך קבוצת הקהילה של האקדמיה ולצרף לדיווח.
    `,
    points: 120,
    tasks: {
      other: {
        title: 'הדיווח שלי',
        type: 'image'
      }
    }
  },
  mission6: {
    index: 6,
    title: 'מה חשוב לך?',
    ends: 'מסתיימת ב-08/07',
    externalLink:
      'https://www.facebook.com/karinn.emanuel/posts/6412409105503858',
    description: `
      קארין פירסמה בפרופיל האישי שלה פוסט.

      המשימה שלך היא להגיב לפוסט ופשוט לכתוב את דעתך.

      המשימה הזו שווה 100 נקודות!

      יש לך לדווח עם תצלום של התגובה שלך.

      שימי לב שניתן לבצע את המשימה עד יום שבת (08/07) בחצות.
    `,
    points: 100,
    tasks: {
      other: {
        title: 'הדיווח שלי',
        type: 'image'
      }
    }
  },
  mission7: {
    index: 7,
    title: 'סייימנו !!!',
    ends: 'מסתיימת ב-09/08',
    description: `
      לכל מי שמתבאסת שכבר סיימה קורס אחד או יותר ולכן משימה 4 לא רלוונטית עבורה,
      מי שקיבלה תעודה או שהתעצלה להוריד אותה,
      זו המשימה בשבילך!

      המשימה היא להעלות פוסט הכולל את
      תמונת התוצר הסופי של הקורס ותעודת הסיום.

      את הפוסט יש להעלות בקבוצת הפייסבוק:
      כיתת אומן | עיצוב עוגות בבצק סוכר.
      (רק מי שאין לה פייסבוק, שתפנה אליי ונמצא פתרון)

      בפוסט צייני שזה נעשה במסגרת קורס באקדמיה לעיצוב עוגות וספרי באיזה מצב היית לפני הקורס ומה לקחת מהקורס הזה.

      מה התוצאה שהצלחת להשיג: קצב עבודה מהיר יותר, גימור חלק יותר, פיסול פנים מדוייק יותר וכו׳.

      חשוב - אם היו קשיים בדרך, צייני אותם. זה לא צריך להיות רק מהמם ונהדר. אם יש קושי זה רק מגביר את האמינות של הביקורת.

      אנא מכן, הישארו ממוקדות.
      בלי לספר סיפור על העוגה שהכנת, בלי הסחות דעת.

      שימי לב שניתן לבצע את המשימה עד סוף המשחק אז תכנני את הזמן בהתאם.
      במידה ויש לך כמה קורסים, תוכלי לפרסם את כולם ולזכות בנקודות נוספות.
      אך לא ניתן להעלות את כולם באותו השבוע. חישבי איך לחלק את זה בצורה הגיונית.

      המשימה הזו שווה 1000 נקודות!

      יש לדווח עם קישור לפוסט שלך בקבוצת הפייסבוק ״כיתת אומן | עיצוב עוגות בבצק סוכר.״.
    `,
    points: 1000,
    tasks: {
      course1: {
        title: 'קורס 1',
        type: 'link'
      },
      course2: {
        title: 'קורס 2',
        type: 'link'
      },
      course3: {
        title: 'קורס 3',
        type: 'link'
      },
      course4: {
        title: 'קורס 4',
        type: 'link'
      },
      course5: {
        title: 'קורס 5',
        type: 'link'
      }
    }
  },
  mission8: {
    index: 8,
    title: 'מי אני ומה שמי?',
    ends: 'מסתיימת ב-15/07',
    description: `
        כל מי שחושבת שזה מספיק שהיא תשים את העוגות היפות שלה בפייסבוק/אינסטגרם ואת הלקוח לא מעניין מי עומדת מאחורי העוגות האלו,
        אז שתדעי שכלל מספר 1 בשיווק של עסקים קטנים ובינוניים כמו שלנו:
        אנשים קונים מאנשים
        לא, זה לא מעניין אותו איך נראה הלוגו שלך.
        זה לא מעניין אותו המיתוג שלך.
        מעניין אותו להכיר אותך.

        אז המשימה הפעם קשורה בקידום האישי של כל אחת מכן.
        ועל הדרך אתן יכולות לספר על ההכשרה המקצועית שלכן באקדמיה לעיצוב עוגות. כי זה מרים לכן באופן אישי 😊

        אז המשימה היא…
        לכתוב פוסט בפרופיל האישי שלכן,
        שבו אתן מספרות על עצמכן.
        זה יכול להיות סיפור ארוך או קצר, לשיקולכן.
        אתן כמה נושאים לדוגמה שנחמד לכתוב עליהם בפוסט כזה:

        1. למה התחלת לעצב עוגות
        2. למי את חולמת לעצב עוגה
        3. 10 דברים שלא ידעתם עליי
        4. מה החלום שלך בעיצוב עוגות

        המשימה הזו שווה 250 נקודות!

        יש לדווח עם קישור לפוסט שלך.
      `,
    points: 250,
    tasks: {
      report: {
        title: 'הדיווח שלי',
        type: 'link'
      }
    }
  },
  mission9: {
    index: 9,
    title: 'יוצאת אל האור',
    ends: 'מסתיימת ב-15/07',
    description: `
      כלל מס׳ 1 בשיווק:
      אנשים קונים מאנשים

      אז כדאי שהעוקבים שלך יידעו איך את נראית.

      מי שאין לה בעיה להצטלם - מהמם! המשיכי כך!
      מי שבתמונת הפורפיל שלה עדיין תקוע איזה לוגו או עוגה - אסור לך לוותר על המשימה הזו.

      כשאני התחלתי להצטלם עם העוגות שלי, למרות כל החששות שהיו לי מהמצלמה, זה העיף את השיווק שלי למעלה.
      ואני ממש ממליצה לכן לחבק את המשימה הזו.

      אז המשימה היא…
      להעלות בקבוצת הוואטסאפ של האקדמיה תמונה שלך עם עוגה או עם דמות.
      ולצטט את התגובה הכי מרגשת שקיבלת מלקוח (או מישהו שהכנת לו עוגה)

      אין צורך לחפור ולהציג את עצמך שוב וכל זה. רק תמונה ומשפט

      אם עדיין אין לך תמונה כזאת, אז יש לך זמן להצטלם עד יום שבת בחצות 🥰

      ואם את רוצה לקחת את המירוץ הזה ולקדם את השיווק שלך
      אז כבר היום תחליפי את תמונת הפרופיל בווטסאפ שלך לתמונה שאת מעלה בקבוצה 😊

      המשימה הזו שווה 200 נקודות!

      יש לך לדווח עם תצלום של ההודעה שלך.
      `,
    points: 200,
    tasks: {
      report: {
        title: 'הדיווח שלי',
        type: 'image'
      }
    }
  },
  mission10: {
    index: 10,
    title: 'מה כבר אמרתי?!',
    ends: 'מסתיימת ב-22/07',
    externalLink: 'https://g.page/r/CR1TeZMJlUAdEB0/review',
    description: `
      השיווק הכי טוב שיכול להיות הוא ביקורות של לקוחות (ואני ממליצה לכולכן לשמור ביקורות ולשים אותן בכל מקום שהקהל שלכן יכול לראות).

      המשימה היא לכתוב ביקורת מעולה בפרופיל גוגל מיי ביזנס (גוגל לעסק שלי) שלי שתדבר על אחד או יותר מהנושאים הבאים:

      1. הקורסים באקדמיה לעיצוב עוגות.
      2. קורס ספציפי שאת רוצה לספר עליו.
      3. המעטפת של האקדמיה.
      4. אחת ההדרכות שהשפיעו עלייך.
      5. אתגר פיסול שממש קידם אותך.

      או כל דבר נוסף שקשור לחוויה שלך באקדמיה.

      צריך גם לדרג (עד 5) ואפשר גם לצרף תמונה שתומכת בביקורת שלכן.

      ביקורת טובה מורכבת מ-3 חלקים:

      1. באיזה מקום הלקוח היה לפני שהגיע אלייך, מה בעצם גרם לו לפנות דווקא אלייך.
      2. איפה הוא היום אחרי שקנה ממך, מה זה נתן לו, מה ההשפעה של המוצר שלך על החיים שלו.
      3. תיאור הדרך - אם היו קשיים. חשוב לציין אותם. אם היה סקפטי, אם היו חששות. לציין הכל.

      ביקורת שבה כתבו לך רק כמה שאת מהממת או כמה שאת מספר אחת היא לא ביקורת טובה (אף על פי שזה מאוד נחמד לנו לקרוא).

      אני יודעת שאתן קוראות את זה וחושבות שזה לא רלוונטי לעוגות אך זה מאוד רלוונטי גם לעוגות.
      נתתי דוגמה לזה בתוך קבוצת הקהילה.

      המשימה הזו שווה בין 50 ל-1000 נקודות

      מי שתכתוב ביקורת מעולה (!) תקבל 1000 נקודות.
      מי שתכתוב ביקורת טובה אך לא לפי הפורמט, תקבל 500 נקודות.
      מי שתכתוב ביקורת רק כדי לסמן וי על המשימה, תקבל 50 נקודות.

      אין צורך לדווח כי אנחנו נהיה אלו שנחליט על איכות הביקורת ונעדכן את הנקודות בהתאם.
    `,
    points: 1000,
    tasks: {
      other: {
        title: 'הדיווח שלי',
        type: 'link'
      }
    }
  },
  mission11: {
    index: 11,
    title: 'אתגר המעלית',
    ends: 'מסתיימת ב-24/07',
    description: `
      מכירות את נאום המעלית?
      הרעיון הוא שאת נכנסת למעלית עם מישהו שאת רוצה לגרום לו להשקיע בך, יש לך 60 שניות - מה את אומרת לו? איך את מציגה את עצמך?

      יש לך בעצם בדיוק 60 שניות להציג את עצמך.
      עופי על זה!

      אז אחרי ששברנו את מחסום הכתיבה, והתמונה,
      במשימה הזו אנחנו הולכות לנפץ את מחסום הסרטון!

      לפני שאני מאבדת אתכן. תישארו כאן,
      כי זה הולך להיות אחד התרגולים הכי טובים אבר!

      אז המשימה היא…
      לצלם סרטון באורך של דקה לכל היותר בו את מספרת על עצמך ועל העסק שלך.
      דקה זה לא הרבה זמן,
      נסו להישאר ממוקדות ולומר בסרטון את כל הדברים הכי מעניינים שיגרמו ללקוח להבין למה הוא קונה את העוגה לאירוע שלו אך ורק אצלכן!

      את הסרטון מומלץ לצלם לגובה.

      המשימה הזו שווה 500 נקודות! אם את משתפת את הסרטון בתוך קבוצת האקדמיה.
      והיא שווה לך עוד 1000 נקודות אם את משתפת את הסרטון אצלך בדף האישי (פייסבוק או אינסטה, מה שחזק יותר אצלך)

      יש לדווח עם תצלום המסך של השיתוף בקבוצת האקדמיה בוואטסאפ ועם הקישור לסרטון באחת הרשתות החברתיות.

    `,
    points: 500,
    tasks: {
      whatsapp: {
        title: 'קבוצת האקדמיה בוואטסאפ',
        type: 'image',
        points: 500
      },
      social: {
        title: 'פרופיל הפייסבוק או האינסטגרם',
        type: 'link',
        points: 1000
      }
    }
  },
  mission12: {
    index: 12,
    title: 'אני באה',
    ends: 'מסתיימת ב-23/07',
    externalLink:
      'https://www.facebook.com/100002044968234/posts/6464180803660021/',
    description: `
      קארין פירסמה בפרופיל האישי שלה פוסט.

      המשימה שלך היא להגיב לפוסט ולכתוב מה קיבלת מאחת ההדרכות של קארין (לייב או הקלטה) בנושאים שאינם קשורים ישירות לבצק סוכר (עסקי, תודעתי וכו׳) ומה את מצפה לקבל מההדרכה הזו.

      המשימה הזו שווה 250 נקודות!

      יש לך לדווח עם תצלום של התגובה שלך.

      שימי לב שניתן לבצע את המשימה עד יום ראשון (23/07) בחצות.
    `,
    points: 250,
    tasks: {
      other: {
        title: 'הדיווח שלי',
        type: 'image'
      }
    }
  },
  mission13: {
    index: 13,
    title: 'זה משתלם?',
    ends: 'מסתיימת היום ב - 20:00',
    externalLink:
      'https://www.facebook.com/groups/287740369692449/posts/828305532302594/',
    description: `
      קארין פירסמה בקבוצת הפייסבוק מיקסר פוסט.

      המשימה שלך היא להגיב לפוסט, לצרף תמונה של אחד מאתגרי פיסול שעשית באקדמיה שאת הכי גאה בתוצאה שיצאה לך ולענות על השאלה בסוף הפוסט.

      המשימה הזו שווה 250 נקודות!

      יש לך לדווח עם תצלום של התגובה שלך.

      שימי לב שניתן לבצע את המשימה עד היום בשעה 20:00.
    `,
    points: 250,
    tasks: {
      other: {
        title: 'הדיווח שלי',
        type: 'image'
      }
    }
  },
  mission14: {
    index: 14,
    title: 'מה השאלה?',
    ends: 'מסתיימת מחר (יום רביעי) ב - 12:00 (!)',
    description: `
      אחד הכללים הכי חשובים בשיווק זה להכיר את הקהל שלך כמו את כף ידך.

      כחלק מיצירת סדרת סרטונים שאנחנו מצלמים לשיווק האקדמיה, עלינו לעשות רשימה מפורטת של כל ההתלבטויות והשאלות של נשים שמתלבטות האם להצטרף לאקדמיה.

      המשימה שלך היא לכתוב בקבוצת הקהילה בוואטסאפ הודעה ובה כל השאלות וההתלבטויות שהיו לך לפני שהחלטת להצטרף לאקדמיה (או שעשויות להיות למתעניינות אחרות).
      (אם יש משהו מאוד אישי ואת מעדיפה לכתוב לי בפרטי זה בסדר גמור - הכי חשוב שתכתבי לי את כל השאלות וההתלבטויות שהיו לך).

      המשימה הזו שווה 150 נקודות!

      יש לך לדווח עם תצלום של ההודעה שלך.
    `,
    points: 150,
    tasks: {
      other: {
        title: 'הדיווח שלי',
        type: 'image'
      }
    }
  },
  mission15: {
    index: 15,
    title: 'החוויה שלך',
    ends: 'מסתיימת ב-30/07',
    externalLink:
      'https://www.facebook.com/karinn.emanuel/posts/pfbid0gdrAvoJTra8tPyMmMb3fdhkCMY2e6evBYssY7uDxHHGGDVjLYq3XTCa7DnTYBHoal',
    description: `
      קארין פירסמה בפרופיל האישי שלה פוסט.

      המשימה מיועדת למי שהשתתפה אי פעם בלייב שואו של קארין (גם רפיקי).

      המשימה שלך היא להגיב לפוסט ולספר על החוויה שלך מהלייב שואו ומה הדבר הכי עוצמתי שלקחת ממנו.

      המשימה הזו שווה 300 נקודות!

      יש לדווח עם תצלום של התגובה שלך.
    `,
    points: 300,
    tasks: {
      other: {
        title: 'הדיווח שלי',
        type: 'image'
      }
    }
  },
  mission16: {
    index: 16,
    title: 'איפה למדתי',
    ends: 'מסתיימת ב-05/08',
    description: `
      בפרופיל הפייסבוק האישי אפשר להוסיף את כל מוסדות הלימוד שלמדת בהן.

      המשימה שלך היא להוסיף באפשרות של ״הוספת אוניברסיטה/מכללה״ (או college למי שזה באנגלית אצלה) את קארין עמנואל - האקדמיה לעיצוב עוגות (תתחילי להקליד וזה כבר יופיע).

      המשימה הזו שווה 250 נקודות!

      יש לדווח עם תצלום של העדכון שלך.
    `,
    points: 250,
    tasks: {
      other: {
        title: 'הדיווח שלי',
        type: 'image'
      }
    }
  },
  mission17: {
    index: 17,
    title: 'מה כבר אמרתי 2?!',
    ends: 'מסתיימת ב-07/08',
    externalLink: 'https://www.facebook.com/karinnarts/reviews',
    description: `
      השיווק הכי טוב שיכול להיות הוא ביקורות של לקוחות (ואני ממליצה לכולכן לשמור ביקורות ולשים אותן בכל מקום שהקהל שלכן יכול לראות).

      המשימה היא לכתוב ביקורת מעולה בדף הפייסבוק שלי (קארין עמנואל - האקדמיה לעיצוב עוגות) שלי שתדבר על אחד או יותר מהנושאים הבאים:

      1. הקורסים באקדמיה לעיצוב עוגות.
      2. קורס ספציפי שאת רוצה לספר עליו.
      3. המעטפת של האקדמיה.
      4. אחת ההדרכות שהשפיעו עלייך.
      5. אתגר פיסול שממש קידם אותך.

      או כל דבר נוסף שקשור לחוויה שלך באקדמיה.

      ניתן גם לצרף תמונה שתומכת בביקורת שלכן.

      ביקורת טובה מורכבת מ-3 חלקים:

      1. באיזה מקום הלקוח היה לפני שהגיע אלייך, מה בעצם גרם לו לפנות דווקא אלייך.
      2. איפה הוא היום אחרי שקנה ממך, מה זה נתן לו, מה ההשפעה של המוצר שלך על החיים שלו.
      3. תיאור הדרך - אם היו קשיים. חשוב לציין אותם. אם היה סקפטי, אם היו חששות. לציין הכל.

      ביקורת שבה כתבו לך רק כמה שאת מהממת או כמה שאת מספר אחת היא לא ביקורת טובה (אף על פי שזה מאוד נחמד לנו לקרוא).

      לא ניתן להשתמש באותה הביקורת שכתבתן בפרופיל גוגל מיי ביזנס שלי והמטרה היא שתתרגלו כתיבה על אותו נושא ממספר זויות שונות.

      המשימה הזו שווה בין 50 ל-1000 נקודות

      מי שתכתוב ביקורת מעולה (!) תקבל 1000 נקודות.
      מי שתכתוב ביקורת טובה אך לא לפי הפורמט, תקבל 500 נקודות.
      מי שתכתוב ביקורת רק כדי לסמן וי על המשימה, תקבל 50 נקודות.

      הקישור לכתיבת ביקורת עובד רק למי שפותחת אותו במחשב.
      למי שפותחת מהטלפון, צריך לפתוח את דף הפייסבוק ״קארין עמנואל - האקדמיה לעיצוב עוגות״ ושם יחסית למעלה תחת ״פרטים״ יש שורה שכתוב בה ״דירוג״.
      תלחצי עליה ותהיה לך אפשרות לכתוב ביקורת.

      אין צורך לדווח כי אנחנו נהיה אלו שנחליט על איכות הביקורת ונעדכן את הנקודות בהתאם.
    `,
    points: 1000,
    tasks: {
      other: {
        title: 'הדיווח שלי',
        type: 'link'
      }
    }
  },
  mission18: {
    index: 18,
    title: 'עוגה יפה לעוגה שאי אפשר להתעלם ממנה',
    ends: 'מסתיימת ב-13/08',
    personalLink:
      'https://www.karinnarts.co.il/academy/cakes-you-cannot-ignore/',
    description: `
      המשימה היא לצרף חברות לתוכנית החדשה שמתחילה ב-13/08 בכל דרך שאת רואה לנכון (כל עוד זה אותנטי ולא אגרסיבי).

      הניקוד יתקבל על סמך תוצאות בלבד ועל כל מצטרפת את מקבלת 1000 (!) נקודות.

      אין צורך לדווח על ביצוע המשימה כי אנחנו נדע לפי הצטרפות דרך הלינק האישי שלך.
    `,
    points: 1000,
    tasks: {
      ref: {
        title: 'הזמנת חברות',
        ignore: true,
        points: 1000
      }
    }
  },
  mission19: {
    index: 19,
    title: 'איפה הכסף',
    ends: 'מסתיימת ב-09/08',
    personalLink: 'https://www.karinnarts.co.il/academy/webinar/',
    description: `
      כמו שראיתן, זה לא כל כך פשוט למכור ישר תוכנית אפילו אם היא עולה 297₪.

      אנחנו שמחים שהתנסתן בזה בעצמכן ועכשיו אנחנו רוצים להציע את הדרך שמביאה תוצאות טובות יותר.
      והיא הדרכה חינמית מעולה שקארין תעביר ובסופה תציע להצטרף לתוכנית.

      המשימה שלכן היא להזמין חברות להירשם ולהשתתף בהדרכה.

      אם מישהי שאתן הזמנתן להדרכה, תצטרף לתוכנית, אתן מקבלות את הנקודות דרך משימה #18.

      בשורה התחתונה, אתן מזמינות למשהו חינמי, קארין עושה את העבודה הקשה של המכירה ואתן מקבלות את הנקודות עבור כל מצטרפת.
    `,
    points: 0,
    tasks: {}
  },
  mission20: {
    index: 20,
    title: 'עוגת החלומות שלי',
    ends: 'מסתיימת ב-09/08',
    description: `
      הדרך לגרום לקהל שלך להכיר אותך ולהתחבר אלייך הוא לא רק דרך זה שאת כותבת באופן ישיר על עצמך או מספרת לו דברים שהוא לא ידע עלייך.
      אלא בעיקר דרך סיפורים עקיפים.

      במשימה האחרונה במירוץ ננסה לתת לקהל להכיר אותך בדרך עקיפה ומעניינת.
      בד״כ אתן שומעות את החלומות של הלקוחות שלכן ומתאימות להם את העיצוב המרגש ביותר
      הפעם אתן הולכות לספר מהי עוגת החלומות שלך.
      אז אם את היית מקבלת עוגה מעוצבת אז איך היית רוצה שהיא תיראה ולמה.
      שימי לב - החלק של הלמה הוא קריטי כדי לגרום לקהל להבין ולהתחבר. זה החלק המעניין באמת.

      אני ממש ממליצה לא לכתוב שעוגת החלומות שלך היא עוגת גבינה של סבתא רבתא שלך גם אם זה נכון
      כי זה פשוט לא יקדם את העוגות המעוצבות שלך 😉
      תזכרו שהמטרה היא שיווק.
      אם את משווקת עוגת גבינה אז זה מצויין אבל אם את משווקת עוגות מעוצבות אז ספרי איך הולכת להיראות העוגה שלך מבחינת העיצוב שלה.

      אה, ופליז אל תספרו כמה שהעוגה טעימה מבפנים ועשויה מחומרי הגלם הכי טובים.
      מה שיהפוך את זה למעולה זה אם הקהל שלך יבין מה מרגש אותך ואיך את הולכת להביא את זה לידי ביטוי בעוגה.
      כי הוא יכול לדמיין את עצמו ואיזו עוגה את תכיני בשבילו.

      אז המשימה שלך היא לכתוב פוסט בפרופיל האישי בפייסבוק או באינסטגרם (בערוץ הכי פעיל שלך) על עוגת החלומות שלך.

      אה, ואיזו תמונה לשים?
      אני ממליצה לשים תמונה שלכן או סקיצה של העוגה שאתן חולמות עליה.

      המשימה הזו שווה 500 נקודות!

      יש לדווח עם קישור לפוסט שלך.
    `,
    points: 500,
    tasks: {
      other: {
        title: 'הדיווח שלי',
        type: 'link'
      }
    }
  }
}

export const LIVE_MISSIONS = {}
