export const isBrowser = typeof window !== `undefined`
export const isMobile =
  isBrowser &&
  (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  ) ||
    ('ontouchstart' in document.documentElement &&
      navigator.userAgent.match(/Mobi/)))

export function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export const isSupportsWebp = (function () {
  if (!isBrowser) return false
  var elem = window.document.createElement('canvas')
  if (elem.getContext && elem.getContext('2d')) {
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0
  }
  // very old browser like IE 8, canvas not supported
  return false
})()

export const getParams = (search) => {
  search = search.slice(1)
  return search.split('&').reduce((obj, keyval) => {
    const tuple = keyval.split('=')
    obj[tuple[0]] = tuple[1]
    return obj
  }, {})
}

export const getCookies = (cookie) => {
  return cookie.split(/; */).reduce((cookies, cookie) => {
    const cookieKeyVal = cookie.split('=')
    cookies[cookieKeyVal[0]] = cookieKeyVal[1]
    return cookies
  }, {})
}

export const prettyPrice = (price) => {
  let p = price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  const beforeAndAfterDecimalPoint = p.split('.')
  if (beforeAndAfterDecimalPoint[1] === '00') {
    p = beforeAndAfterDecimalPoint[0]
  }
  return p
}

export async function sha256(message) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message)

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer))

  // convert bytes to hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')

  return hashHex
}

export const fetchWitRetry = async (url, options, n = 3) => {
  let signal, timer
  if (window.AbortController) {
    const controller = new AbortController()
    signal = controller.signal
    const reason = new DOMException('signal timed out', 'TimeoutError')
    timer = setTimeout(() => controller.abort(reason), 15000)
  }
  try {
    const response = await window.fetch(url, { signal, ...options })
    clearTimeout(timer)
    return response
  } catch (err) {
    clearTimeout(timer)
    if (n === 1) throw err
    return await fetchWitRetry(url, options, n - 1)
  }
}

export const coursesImagesMapping = (images) => {
  const {
    sugarDoughBasics,
    baseCakeBasics,
    cakeDesignEquipment,
    sculptingMethod,
    bear,
    rabbit,
    airplanes,
    tallSafari,
    tallCircus,
    tallButterflies,
    giftBox,
    tiersCastle,
    princess,
    ballerina,
    llama,
    safariAnimalsPro,
    circusAnimalsPro,
    safariAnimalsMasters,
    tractor,
    jeepSafariAnimals,
    tallPatches,
    volcano,
    dinosaurs,
    calimero,
    zebra,
    dino,
    dog,
    bigRabbit,
    stork,
    clown,
    dwarf,
    monster,
    pooh,
    crocodile,
    turtle,
    duck,
    grouchySmurf,
    eeyore,
    ostrich,
    faces,
    timon,
    lumiere,
    cookiemonster,
    cups,
    candies,
    bambi,
    horse,
    surfer,
    farmer,
    waiter,
    flamingo,
    flamingo2,
    dinosaur,
    heroes,
    begginers,
    indianCake,
    amsterdamCake,
    indianGirl,
    giantCat,
    kitchen,
    playingKids,
    bunny
  } = images

  return {
    'יסודות לעבודה עם בצק סוכר': sugarDoughBasics,
    'יסודות ליצירת עוגת הבסיס': baseCakeBasics,
    'הציוד ההכרחי לעיצוב עוגה': cakeDesignEquipment,
    'אמנות הפיסול ב-5 שלבים': sculptingMethod,
    'עוגת קופסת מתנה': giftBox,
    'עוגת דובי': bear,
    'עוגת ארנבים': rabbit,
    'עוגת מטוסים': airplanes,
    'עוגת טלאים': tallPatches,
    'עוגת ספארי': tallSafari,
    'עוגת קרקס': tallCircus,
    'עוגת פרפרים': tallButterflies,
    'עוגת טירה': tiersCastle,
    נסיכה: princess,
    בלרינה: ballerina,
    'חיות ספארי': safariAnimalsPro,
    'חיות קרקס': circusAnimalsPro,
    לאמה: llama,
    דינוזאורים: dinosaurs,
    'חיות ספארי קומיות': safariAnimalsMasters,
    'עוגת טרקטור': tractor,
    'עוגת הר געש': volcano,
    'עוגת אפרוחים': calimero,
    'עוגת ג׳יפ ספארי': jeepSafariAnimals,
    זברה: zebra,
    דינו: dino,
    'כלב ענק': dog,
    'ארנב ענק': bigRabbit,
    חסידה: stork,
    ליצנית: clown,
    גמד: dwarf,
    מפלצת: monster,
    'פו הדב': pooh,
    תנין: crocodile,
    צב: turtle,
    ברווז: duck,
    רגזני: grouchySmurf,
    איה: eeyore,
    יען: ostrich,
    פרצופים: faces,
    טימון: timon,
    לומייר: lumiere,
    ארנבת: bunny,
    עוגיפלצת: cookiemonster,
    'עוגת ספלים שיגיונית': cups,
    'עוגת ממתקים': candies,
    במבי: bambi,
    סוס: horse,
    'חקלאי הומוריסטי': farmer,
    'גולש הומוריסטי': surfer,
    'מלצר הומוריסטי': waiter,
    'עוגת פלמינגו': flamingo,
    פלמינגו: flamingo2,
    דינוזאורית: dinosaur,
    'עוגת גיבורי על': heroes,
    'עוגת נערה הודית': indianCake,
    'עוגת טיול באמסטרדם': amsterdamCake,
    'נערה הודית': indianGirl,
    'עוגת חתול ענק': giantCat,
    'עוגת שמח במטבח': kitchen,
    'ילדים מנגנים': playingKids,
    'יסודות פיסול דמויות בבצק סוכר': begginers
  }
}
