import React, { createContext, useContext, useState } from 'react'
import * as Sentry from '@sentry/gatsby'
import { MISSIONS } from '../raceConfig'
import {
  persistPersonalData,
  removePersonalData,
  configureClientsWithPersonalData
} from '../analytics'
import { fetchWitRetry } from '../utils'
/* alternative way of generating nonces
 * https://github.com/WP-API/docs/pull/20#issuecomment-370286436
 * https://developers.wpengine.com/blog/headless-wordpress-authentication-native-cookies
 * https://gist.github.com/izzygld/5a424641659c00fca4b7c37f58c20b55
 * https://www.youtube.com/watch?v=eeWxvCZHxLs
 * https://stackoverflow.com/questions/6808221/php-within-js-file-wordpress
 * https://stackoverflow.com/questions/48299823/proper-way-to-echo-json-in-php
 * https://stackoverflow.com/questions/14662927/how-can-i-store-javascript-variable-output-into-a-php-variable
 * https://stackoverflow.com/questions/2810124/how-can-i-add-a-php-page-to-wordpress
 * https://wordpress.stackexchange.com/questions/143297/how-to-create-custom-php-script-page
 * https://codex.wordpress.org/WordPress_Nonces
 */

// https://dev.to/finiam/predictable-react-authentication-with-the-context-api-g10

function sortRace(race) {
  if (!race) return []
  race.forEach((item) => {
    item.points = item.missions.reduce((total, mission) => {
      mission.points = mission.tasks.reduce((total, task) => {
        let points = MISSIONS[mission.id]['points']
        if (
          MISSIONS[mission.id] &&
          MISSIONS[mission.id]['tasks'][task.id] &&
          MISSIONS[mission.id]['tasks'][task.id]['points']
        ) {
          points = MISSIONS[mission.id]['tasks'][task.id]['points']
        }
        task.points = (task.count || 1) * points
        return total + task.points
      }, 0)
      return total + mission.points
    }, 0)
  })
  const totalPoints = race.reduce((total, item) => total + item.points, 0)
  const totalAward = Math.ceil(race.length / 10) * 1000
  race.forEach((item) => {
    item.award = Math.round(totalAward * (item.points / totalPoints))
  })
  race = race.sort((item1, item2) => item2.points - item1.points)
  return race
}

const AuthContext = createContext()

const API = {
  authData: async function () {
    try {
      const response = await fetchWitRetry(
        `/core/wp/?action=api&method=get_auth_data`
      )
      const result =
        response.status === 200 ? await response.json() : await response.text()
      if (response.status !== 200) {
        if (response.status !== 401) {
          Sentry.captureException(
            new Error(`${response.status} ${response.statusText} ${result}`)
          )
        }
        return
      }

      return result
    } catch (e) {
      Sentry.captureException(e)
      return {
        user: null
      }
    }
  },
  login: async function (body) {
    let result = { isSuccess: false }
    try {
      const response = await fetchWitRetry(`/api/loginData/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      })
      if (response.status === 200) {
        result = await response.json()
      } else {
        Sentry.captureException(
          new Error(
            `${response.status} ${response.statusText} ${await response.text()}`
          )
        )
      }
    } catch (e) {
      Sentry.captureException(e)
    }
    return result
  },
  logout: async function () {
    let result = { isSuccess: false }
    try {
      const response = await fetchWitRetry(`/core/wp/?action=api&method=logout`)
      if (response.status === 200) {
        result = await response.json()
      } else {
        Sentry.captureException(
          new Error(
            `${response.status} ${response.statusText} ${await response.text()}`
          )
        )
      }
    } catch (e) {
      Sentry.captureException(e)
    }
    return result
  },
  getRaceData: async function () {
    try {
      const response = await fetchWitRetry(
        `/core/wp/?action=api&method=get_race_data`,
        {
          method: 'POST'
        }
      )
      const result =
        response.status === 200 ? await response.json() : await response.text()
      if (response.status !== 200) {
        if (response.status !== 401) {
          Sentry.captureException(
            new Error(`${response.status} ${response.statusText} ${result}`)
          )
        }
        return
      }

      return result
    } catch (e) {
      Sentry.captureException(e)
      return {
        user: null
      }
    }
  },
  addRaceData: async function (body) {
    try {
      const response = await fetchWitRetry(
        `/core/wp/?action=api&method=add_race_data`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body)
        }
      )
      const result =
        response.status === 200 ? await response.json() : await response.text()
      if (response.status !== 200) {
        if (response.status !== 401) {
          Sentry.captureException(
            new Error(`${response.status} ${response.statusText} ${result}`)
          )
        }
        return
      }

      return result
    } catch (e) {
      Sentry.captureException(e)
      return {
        user: null
      }
    }
  },
  deleteRaceData: async function (body) {
    try {
      const response = await fetchWitRetry(
        `/core/wp/?action=api&method=delete_race_data`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body)
        }
      )
      const result =
        response.status === 200 ? await response.json() : await response.text()
      if (response.status !== 200) {
        if (response.status !== 401) {
          Sentry.captureException(
            new Error(`${response.status} ${response.statusText} ${result}`)
          )
        }
        return
      }

      return result
    } catch (e) {
      Sentry.captureException(e)
      return {
        user: null
      }
    }
  }
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState()
  const [isAdmin, setIsAdmin] = useState(false)
  const [isInAcademy, setIsInAcademy] = useState(false)
  const [myCourses, setMyCourses] = useState([])
  const [otherCourses, setOtherCourses] = useState([])
  const [challengeCourses, setChallengeCourses] = useState([])
  const [sugarDoughCourses, setSugarDoughCourses] = useState([])
  const [artCourses, setArtCourses] = useState([])
  const [businessCourses, setBusinessCourses] = useState([])
  const [sculptureMethodCourse, setSculptureMethodCourse] = useState(null)
  const [nextChallengeCourse, setNextChallengeCourse] = useState({
    course: null,
    isRegistered: false,
    isShowRegistration: false,
    isShowLink: false,
    date: null,
    day: null,
    time: null,
    displayDateTime: null,
    link: null
  })
  const [nextZoomMeeting, setNextZoomMeeting] = useState({
    isShowLink: false,
    date: null,
    day: null,
    time: null,
    displayDateTime: null,
    link: null
  })
  const [enrolledCourses, setEnrolledCourses] = useState([])
  const [loading, setLoading] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [race, setRace] = useState([])
  const [currentUserRaceData, setCurrentUserRaceData] = useState([])

  async function authenticate() {
    setLoading(true)
    try {
      const {
        user,
        isAdmin,
        isInAcademy,
        enrolledCourses,
        myCourses,
        otherCourses,
        challengeCourses,
        sugarDoughCourses,
        artCourses,
        businessCourses,
        sculptureMethodCourse,
        nextZoomMeeting,
        nextChallengeCourse,
        race
      } = await API.authData()

      setUser(user)
      setIsAdmin(isAdmin)
      setIsInAcademy(isInAcademy)
      setEnrolledCourses(enrolledCourses)
      setMyCourses(myCourses)
      setOtherCourses(otherCourses)
      setChallengeCourses(challengeCourses)
      setSugarDoughCourses(sugarDoughCourses)
      setArtCourses(artCourses)
      setBusinessCourses(businessCourses)
      setSculptureMethodCourse(sculptureMethodCourse)
      const _race = sortRace(race)
      setRace(_race)
      setCurrentUserRaceData(_race.find((item) => item.id === user.id))

      if (nextZoomMeeting) {
        const dom2 = new DOMParser().parseFromString(
          nextZoomMeeting.content,
          'text/html'
        )
        setNextZoomMeeting({
          isShowLink:
            dom2.querySelector('#next-zoom-meeting-is-show-link')
              .textContent === 'true',
          date: dom2.querySelector('#next-zoom-meeting-date').textContent,
          day: dom2.querySelector('#next-zoom-meeting-day').textContent,
          time: dom2.querySelector('#next-zoom-meeting-time').textContent,
          displayDateTime: dom2.querySelector(
            '#next-zoom-meeting-display-datetime'
          ).textContent,
          link: dom2.querySelector('#next-zoom-meeting-link').textContent
        })
      }

      if (nextChallengeCourse) {
        const dom = new DOMParser().parseFromString(
          nextChallengeCourse.content,
          'text/html'
        )
        setNextChallengeCourse({
          course: nextChallengeCourse,
          isRegistered: enrolledCourses.find(
            (course) => course.id === 74615 // אתגר הפיסול הקרוב
          ),
          isShowRegistration:
            dom.querySelector('#next-challenge-is-show-registration')
              .textContent === 'true',
          isShowLink:
            dom.querySelector('#next-challenge-is-show-link').textContent ===
            'true',
          date: dom.querySelector('#next-challenge-date').textContent,
          day: dom.querySelector('#next-challenge-day').textContent,
          time: dom.querySelector('#next-challenge-time').textContent,
          displayDateTime: dom.querySelector('#next-challenge-display-datetime')
            .textContent,
          link: dom.querySelector('#next-challenge-link').textContent
        })
      }

      if (user) {
        configureClientsWithPersonalData(user.fluent_crm_id, user.email)
        persistPersonalData(user)
      }
    } catch (e) {
      Sentry.captureException(e)
    }
    setLoading(false)
    setIsLoaded(true)
  }

  async function logout() {
    setLoading(true)
    await API.logout()
    setUser()
    setIsInAcademy(false)
    setMyCourses([])
    setOtherCourses([])
    setChallengeCourses([])
    setSugarDoughCourses([])
    setArtCourses([])
    setBusinessCourses([])
    setSculptureMethodCourse(null)
    setNextChallengeCourse({
      course: null,
      isRegistered: false,
      isShowRegistration: false,
      isShowLink: false,
      date: null,
      day: null,
      time: null,
      displayDateTime: null,
      link: null
    })
    setNextZoomMeeting({
      isShowLink: false,
      date: null,
      day: null,
      time: null,
      displayDateTime: null,
      link: null
    })
    setEnrolledCourses([])
    setLoading(false)
    removePersonalData()
  }

  async function getRaceData() {
    const race = await API.getRaceData()

    if (!race.isError) {
      const _race = sortRace(race)
      setRace(_race)
      setCurrentUserRaceData(_race.find((item) => item.id === user.id))
    }
  }

  async function addRaceData(body) {
    const race = await API.addRaceData(body)

    if (!race.isError) {
      const _race = sortRace(race)
      setRace(_race)
      setCurrentUserRaceData(_race.find((item) => item.id === user.id))
    }
  }

  async function deleteRaceData(body) {
    const race = await API.deleteRaceData(body)

    if (!race.isError) {
      const _race = sortRace(race)
      setRace(_race)
      setCurrentUserRaceData(_race.find((item) => item.id === user.id))
    }
  }

  const state = {
    user,
    isAdmin,
    isInAcademy,
    myCourses,
    otherCourses,
    challengeCourses,
    sugarDoughCourses,
    artCourses,
    businessCourses,
    sculptureMethodCourse,
    nextChallengeCourse,
    nextZoomMeeting,
    enrolledCourses,
    loading,
    isLoaded,
    authenticate,
    login: API.login,
    logout,
    getRaceData,
    addRaceData,
    deleteRaceData,
    race,
    currentUserRaceData
  }

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

export default function useAuth() {
  return useContext(AuthContext)
}
