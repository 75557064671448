import { loadCSS } from 'fg-loadcss/dist/cssrelpreload'

export const onClientEntry = () => {
  const links = window.document.getElementsByTagName('link')
  for (let i = 0; i < links.length; i++) {
    const link = links[i]
    if (
      link.rel === 'preload' &&
      link.getAttribute('as') === 'style' &&
      !link.getAttribute('data-loadcss')
    ) {
      link.setAttribute('data-loadcss', true)
      loadCSS.relpreload.bindMediaToggle(link)
    }
  }
}
